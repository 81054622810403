<template>
  <div>
    <b-navbar toggleable class="navbarSPL2" :sticky="true">
      <b-navbar-nav class="w-100">
        <b-container class="bv-example-row" fluid>
          <b-row>
            <b-col cols="10" sm="10" md="10" lg="10" xl="5">
              <b-nav-text
                v-if="!mobileMenu"
                class="brandSPL"
                @click="goHome()"
                style="cursor: pointer"
              >
                <b-img
                  class="d-inline"
                  style="max-width: 70px; margin-right: 5px"
                  fluid
                  src="./assets/Logo_filled.svg"
                ></b-img>
                Swiss Digital Shipping Solutions
              </b-nav-text>
              <b-nav-text
                v-else
                class="brandSPL"
                @click="goHome()"
                style="cursor: pointer"
              >
                <b-img
                  class="d-inline"
                  style="max-width: 70px; margin-right: 5px"
                  fluid
                  src="./assets/Logo_filled.svg"
                ></b-img
                >SDSS
              </b-nav-text>
            </b-col>
            <b-col v-if="!mobileMenu" cols="0" lg="5">
              <b-nav style="padding-top: 20px">
                <b-nav-item
                  style="color: black; display: none"
                  :active="true"
                  @click="scrollMeTo('vorteile')"
                  >Vorteile</b-nav-item
                >
                <b-nav-item
                  style="color: black; display: none"
                  :active="true"
                  @click="scrollMeTo('gallerie')"
                  >Gallerie</b-nav-item
                >
                <b-nav-item
                  style="color: black; display: none"
                  :active="true"
                  @click="scrollMeTo('preise')"
                  >Preise</b-nav-item
                >
                <b-nav-item
                  style="color: black; display: none"
                  :active="true"
                  @click="scrollMeTo('integrationen')"
                  >Integrationen</b-nav-item
                >
              </b-nav>
            </b-col>
            <b-col v-if="!mobileMenu" cols="2" lg="2" style="padding-top: 15px">
              <b-button
                class="h-100 btnSPL_2"
                style="max-height: 50px; display: none"
                >Kostenlos testen</b-button
              >
            </b-col>
            <b-col
              style="padding-top: 15px"
              v-if="mobileMenu"
              class="d-flex justify-content-between"
              cols="2"
            >
              <b-button
                class="toggleSPL2"
                :active="true"
                v-b-modal.modalMobileMenu
                @click="expanded = !expanded"
                style="max-height: 45px; display: none"
              >
                <b-icon v-if="expanded" icon="x-lg" rotate="90"></b-icon>
                <b-icon v-else icon="list"></b-icon
              ></b-button>
            </b-col>
            <div
              v-show="expanded"
              style="box-shadow: rgba(0, 0, 0, 0.2) 0px 7px 8px 0px"
            >
              <b-container class="text-right mb-3">
                <br />
                <b-row class="w-100 pb-1" @click="scrollMeTo('vorteile', true)">
                  <b-col cols="12" class="p-0"
                    ><b-button class="w-100 btnSPL_2"
                      ><b-icon icon="box-seam"></b-icon>&emsp;Vorteile
                    </b-button></b-col
                  >
                </b-row>
                <b-row class="w-100 pb-1" @click="scrollMeTo('gallerie', true)">
                  <b-col cols="12" class="p-0"
                    ><b-button class="w-100 btnSPL_2"
                      ><b-icon icon="images"></b-icon>&emsp;Gallerie
                    </b-button></b-col
                  >
                </b-row>
                <b-row class="w-100 pb-1" @click="scrollMeTo('preise', true)">
                  <b-col cols="12" class="p-0"
                    ><b-button class="w-100 btnSPL_2"
                      ><b-icon icon="tag"></b-icon>&emsp;Preise
                    </b-button></b-col
                  >
                </b-row>
                <b-row
                  class="w-100 pb-1 mb-2"
                  @click="scrollMeTo('integrationen', true)"
                >
                  <b-col cols="12" class="p-0"
                    ><b-button class="w-100 btnSPL_2"
                      ><b-icon icon="code"></b-icon>&emsp;Integrationen
                    </b-button></b-col
                  >
                </b-row>
                <b-row class="w-100">
                  <div
                    class="w-100 mb-2"
                    style="background-color: #c0dfd9; height: 2px"
                  ></div>
                  <b-button class="h-100 btnSPL_2">Kostenlos testen </b-button>
                </b-row>
              </b-container>
            </div>
          </b-row>
        </b-container>
      </b-navbar-nav>
    </b-navbar>
    <div class="w-100 p-2 pt-5">
      <h1 class="p-2 text-center">
        Allgemeine Geschäftsbedingungen (AGB) Swiss Digital Shipping Solutions
      </h1>
      <ol>
        <li>
          <b>Geltungsbereich</b>
          <p>
            Innamorato Solutions ist ein Unternehmen mit Sitz in Niederlenz/AG.
            Es ist auf die Erbringung von Dienstleistungen aus dem Bereich ICT
            spezialisiert.
          </p>
          <p>
            Innamorato Solutions bietet Kunden die Möglichkeit an, Versandlabels
            der Post CH AG mit den bereits erfassten Daten aus dem ERP von bexio
            AG automatisiert zu erstellen. Das entsprechende Verfahren ist
            einfach und effizient und ermöglicht es dem Kunden, Zeit zu sparen.
          </p>
          <p>
            Die Dienstleistungen von Swiss Digital Shipping Solutions der
            Innamorato Solutions können mit allen auf dem Schweizer Markt
            erhältlichen Browser fähigen Geräten, die auf den Kunden registriert
            sind, genutzt werden.
          </p>
          <p>
            Der technische Zugang zu Swiss Digital Shipping Solutions erfolgt
            mittels des Internets und dem Link spl.inn-solutions.ch über Browser
            fähige Geräte als persönlichem Terminal des Kunden. Ist die
            Internetverbindung nicht garantiert bzw. die Schnittstelle zu Die
            Post AG CH bzw. bexio AG unterbrochen, so kann die Dienstleistung
            vorübergehend nicht genutzt werden.
          </p>
          <p>
            Mit der Registrierung über den Link spl.inn-solutions.ch bzw. beim
            Bestellvorgang über diesen Link anerkennt der Kunde die vorliegenden
            AGBs in ihrer jeweils geltenden Version, die auf der Website von
            Innamorato Solutions bzw. in der App unter der Bestellmaske abrufbar
            ist.
          </p>
          <p>
            Funktionen können aufgrund regulatorischer Vorgaben durch die
            Innamorato Solutions eingeschränkt werden.
          </p>
          <p>
            Die Versandlabels Swiss Digital Shipping Solutions dienen nur der
            Adressierung von Sendungen, beinhalten aber keine Frankatur und auch
            keine Steuern oder anderweitigen Angaben. Der Kunde ist sich
            bewusst, dass er sich betreffend Frankatur bzw. Steuern oder
            anderweitige Angaben selber kundig machen und für diese besorgt sein
            muss.
          </p>
        </li>
        <li>
          <b>Zugang zu Swiss Digital Shipping Solutions</b>
          <p>
            Der Zugang zu Swiss Digital Shipping Solutions setzt eine Einbindung
            in die Schnittstellen der Die Post CH AG sowie von bexio AG voraus.
          </p>
          <ol>
            <li>
              <b>Rechnungsbeziehung mit der Post CH AG</b>
              <p>
                Der Kunde erklärt, über eine Rechnungsbeziehung mit der Post CH
                AG zu verfügen.
              </p>
            </li>
            <li>
              <b>Frankierlizenz der Post CH AG und API-Zugang</b>
              <p>
                Der Kunde erklärt, über einen Barcode-API Zugang der Post CH AG
                zu verfügen.
              </p>
            </li>
            <li>
              <b>API-Zugang zu bexio AG</b>
              <p>
                Der Kunde erklärt schliesslich, dass er über einen API-Zugang zu
                bexio AG verfügt.
              </p>
            </li>
          </ol>
          <p>
            Innamorato Solutions hat keinen Einfluss darauf, ob die Post CH AG
            bzw. bexio AG dem Kunden diese Zugänge verschafft, noch haftet
            Innamorato Solutions für die Dienstleistungen von die Post CH AG
            bzw. bexio AG (vgl. Ziff. 5 nachstehend):
          </p>
        </li>
        <li>
          <b>Sorgfaltspflichten des Kunden</b>
          <p>
            Im Umgang mit Swiss Digital Shipping Solutions setzt folgende Sorgfaltspflichten des
            Kunden voraus:
          </p>
          <ul>
            <li>
              Schutz der Browser fähigen Geräte vor unberechtigtem Gebrauch
              durch unbefugte Dritte
            </li>
            <li>
              Codes sind geheim zu halten und separat von Browser fähiges Gerät
              aufzubewahren
            </li>
            <li>
              Der gewählte Code darf nicht aus leicht zu ermittelnden
              Kombinationen bestehen
            </li>
            <li>
              Im Schadenfall hat der Kunde Innamorato Solutions bei der
              Aufklärung des Falles und der Schadensminderung zu unterstützen.
              Bei mutmasslich strafbaren Handlungen hat er Strafanzeige bei der
              Polizei zu machen.
            </li>
            <li>
              Bei der Installation der Swiss Digital Shipping Solutions App auf
              dem Browser fähigen Gerät bestätigt der Kunden, dessen
              rechtmässige Nutzer und Eigentümer zu sein. Der Kunde ist für die
              Verwendung der Swiss Digital Shipping Solutions App auf seinem Browser fähigen
              Gerät verantwortlich. Der Kunde trägt demzufolge sämtliche
            </li>
            <li>
              Bei der Installation der Swiss Digital Shipping Solutions App auf
              dem Browser fähigen Gerät bestätigt der Kunden, dessen
              rechtmässige Nutzer und Eigentümer zu sein. Der Kunde ist für die
              Verwendung der Swiss Digital Shipping Solutions App auf seinem Browser fähigen
              Gerät verantwortlich. Der Kunde trägt demzufolge sämtliche
              Konsequenzen, die sich aus der Verwendung von Swiss Digital
              Shipping Solutions auf seinem Browser fähigen Gerät ergeben.
            </li>
            <li>
              Der Kunde hält die standardmässigen Sicherheitsvorkehrungen im
              Umgang mit seinen Codes und Passwörtern für Swiss Digital Shipping
              Solutions ein. Stellt der Kunde fest, dass sich ein unberechtigter
              Dritter Zugang zu diesen Angaben verschafft hat, meldet er dies
              unverzüglich Innamorato Solutions (vgl. nachstehend in Ziff. 8.5.
              unter Kontakt).
            </li>
            <li>
              Der Kunde hat vor jedem Gebrauch zu prüfen, dass die im ERP von
              bexio AG erfasste Adresse des Adressaten des Kunden korrekt,
              vollständig und aktuell ist.
            </li>
            <li>
              Der Kunde meldet von sich aus Innamorato Solutions jegliche
              Änderungen zu seinen Koordinaten und stellt sicher, dass er für
              Innamorato Solutions erreichbar bleibt. Innamorato Solutions ist
              berechtigt, kontaktlose Geschäftsbeziehungen aufzulösen.
            </li>
          </ul>
        </li>
        <li>
          <b>Missbräuche</b>
          <p>
            Stellt Innamorato Solutions oder bexio AG fest, dass das
            Nutzungsverhalten des Kunden vom bestimmungsgemässen Zweck abweicht
            oder besteht ein Verdacht auf vertrags- oder rechtswidriges
            Verhalten des Kunden, so ist Innamorato Solutions ermächtigt, den
            Kunden zur vertrags- bzw. rechtskonformen Verwendung von Swiss Digital Shipping Solutions anzuhalten, die seitens Innamorato Solutions zu erbringenden
            Leistungen ohne Abmahnung einzustellen, zu ändern oder
            einzuschränken, den Nutzungsvertrag mit dem Kunden fristlos und ohne
            Entschädigungsfolge für Innamorato Solutions zu kündigen bzw. bei
            eingetretenem Schaden, der durch das Handeln oder Unterlassen des
            Kunden entstanden ist, Schadenersetz zu verlangen bzw. die
            Freistellung von Ansprüchen Dritter.
          </p>
        </li>
        <li>
          <b>Haftung</b>
          <p>
            Sollte Innamorato Solutions die in den AGB stipulierten Pflichten
            verletzten, so haftet sie für die vom Kunden nachgewiesenen Schäden
            im nachstehend aufgeführten Umfang, sofern Innamorato Solutions
            nicht nachweist, dass sie kein Verschulden trifft.
          </p>
          <p>
            Die Haftung für leichte Fahrlässigkeit ist ausgeschlossen.
            Innamorato Solutions ersetzt Sach- und Vermögensschäden pro
            Schadenereignis im Umfang von CHF 1'000. Die Haftung der Innamorato
            Solutions für allfällige Folgeschäden, entgangenen Gewinn,
            Datenverlust ist, soweit gesetzlich zulässig, ausgeschlossen.
          </p>
          <p>
            Der technische Zugang zu den Dienstleistungen der Innamorato
            Solutions – via die Post CH AG bzw. bexio AG – ist Sache des Kunden.
            Die Innamorato Solutions übernimmt hierfür keine Haftung. Ebenso
            wenig übernimmt sie Haftung für den Netzbetreiber bzw. für die zur
            Verwendung der Dienstleistung der Innamorato Solutions notwendige
            Soft- bzw. Hardware.
          </p>
          <p>
            Die Innamorato Solutions ist bestrebt, einen möglichst störungs- und
            unterbruchfreien Zugang betreffend Swiss Digital Shipping Solutions
            zu ermöglichen. Die Innamorato Solutions kann dies aber nicht zu
            jeder Zeit gewährleisten.
          </p>
          <p>
            Solange Innamorato Solutions die ihr obliegende Sorgfalt wahrnimmt,
            trägt der Kunde einen allfälligen Schaden, der durch solche
            Unterbrüche entstehen kann.
          </p>
        </li>
        <li>
          <b>Entgelt</b>
          <p>
            Der Kunde bezahlt der Innamorato Solutions einen Preis für die
            Nutzung der Swiss Digital Shipping Solutions Dienstleistungen. Die
            Preisgestaltung richtet sich nach Volumina und Funktionalität. Es
            stehen verschiedene Abonnemente zur Verfügung. Die jeweils gültigen
            Preise sind unter Swiss Digital Shipping Solutions (sdss.ch)
            «Preise» abrufbar.
          </p>
        </li>
        <li>
          <b>Elektronische Kommunikation</b>
          <p>
            Die Kommunikation zwischen Innamorato Solutions und dem Kunden
            erfolgt grundsätzlich über die Swiss Digital Shipping Solutions App.
          </p>
          <p>
            Innamorato Solutions darf den Kunden via E-Mail und Telefon
            kontaktieren, sofern er eine solche hinterlegt hat. Der Kunde trägt
            die Verantwortung für die Richtigkeit, Vollständigkeit und
            Aktualität seiner Kontaktangaben.
          </p>
          <p>
            Der Kunde ist sich bewusst, dass elektronische Kommunikation weder
            absolut vertraulich noch sicher ist. Dritte könnten diese einsehen,
            abfangen oder verändern oder sie kann verloren gehen.
          </p>
          <p>
            Sofern der Kunde Innamorato Solutions anschreibt oder Aufträge oder
            Instruktionen erteilt, welche nicht ausdrücklich von Innamorato
            Solutions bestätigt werden, muss davon ausgehen, dass sie nicht bei
            Innamorato Solutions eingegangen sind.
          </p>
          <p>
            Innamorato Solutions übernimmt keine Haftung für die Schäden, welche
            durch elektronische versandte E-Mails entstanden sind.
          </p>
        </li>
        <li>
          <b>Datenschutz</b>
          <ol>
            <li>
              <b
                >Sammlung und Nutzung von Daten zur Verbesserung von Swiss Digital Shipping Solutions</b
              >
              <p>
                Innamorato Solutions nutzt Daten für die Bereitstellung und
                Verbesserung von Swiss Digital Shipping Solutions. Innamorato
                Solutions gibt diese Daten ohne vorgängige Einwilligung des
                Kunden nicht an Dritte weiter, sondern verwendet sie
                ausschliesslich zur Verbesserung von Swiss Digital Shipping
                Solutions.
              </p>
            </li>
            <li>
              <b>Cookies</b>
              <p>
                Innamorato Solutions nutzt in der Swiss Digital Shipping
                Solutions App lediglich technische Cookies sowie local storages.
              </p>
            </li>
            <li>
              <b>Aufbewahrung und Löschung</b>
              <p>
                Die bei Innamorato Solutions gespeicherten personenbezogenen
                Daten des Kunden werden nach einem Jahr gelöscht bzw.
                anonymisiert, wenn sie zur Durchführung der Dienstleistung nicht
                mehr benötigt werden. <br />
                Das erstelle Swiss Post Label (Bilddatei) wird nicht
                gespeichert.
              </p>
            </li>
            <li>
              <b>Hosting ausschliesslich in der Schweiz</b>
              <p>
                Innamorato Solutions arbeitet ausschliesslich mit Schweizer
                Hosts zusammen.
              </p>
            </li>
            <li>
              <b>Auskunft- und Informationsrechte</b>
              <p>
                Innamorato Solutions ist für die Frage zur Bearbeitung von Daten
                unter folgende Adresse erreichbar: <br />
                <a href="mailto:legal@inn-solutions.ch"
                  >legal@inn-solutions.ch</a
                >
              </p>
            </li>
          </ol>
        </li>
        <li>
          <b>Geistiges Eigentum</b>
          <p>
            Für die Dauer des jeweils abgeschlossenen Abonnements erhält der
            Kunde das unübertragbare, nicht-exklusive Recht zur Nutzung der
            Swiss Digital Shipping Solutions App. Das damit verbundene Recht
            ergibt sich aus den vorliegenden AGB.
          </p>
          <p>
            Alle Immaterialgüterrechte verblieben bei der Innamorato Solutions
            bzw. allfällig berechtigten Dritten. Für Open Source Lizenzen gelten
            die gleichen Lizenzen auch bei einer allfälligen Weiterverwendung
            durch den Kunden.
          </p>
          <p>
            Verletzt der Kunde diese Immaterialgüterrechte und wird Innamorato
            Solutions dafür in Anspruch genommen bzw. kommt Innamorato Solutions
            dabei zu Schaden, so verpflichtet sich der Kunde, Innamorato
            Solutions freizuhalten.
          </p>
        </li>
        <li>
          <b>Dauer</b>
          <p>
            Der vorliegende Vertrag wird auf unbestimmte Zeit abgeschlossen.
          </p>
        </li>
        <li>
          <b>Kündigung</b>
          <p>
            Der Kunde kann die App auf seinem Browser fähigen Gerät ohne
            weiteres löschen bzw. beide Vertragspartner können die
            Vertragsbeziehung jederzeit ohne Angaben von Gründen schriftlich
            kündigen.
          </p>
          <p>
            Die Kündigung seitens Innamorato Solutions erfolgt gültig, wenn sie
            an die vom Kunden zuletzt bekannt gegebene Adresse erfolgt.
          </p>
        </li>
        <li>
          <b>Sperrung der Zugänge durch den Kunden</b>
          <p>
            Der Kunde kann die Sperrung seines Zugang zu Swiss Digital Shipping
            Solutions (
            <a href="mailto:legal@inn-solutions.ch">legal@inn-solutions.ch</a> )
            bei Innamorato Solutions beantragen.
          </p>
          <p>
            Die im Zeitpunkt der Beantragung der Sperre bereits ausgelösten
            Swiss Digital Shipping Solutions Dienstleistungen gelten als
            beansprucht und können nicht rückgängig gemacht werden.
          </p>
        </li>
        <li>
          <b
            >Änderungen der Dienstleistungen bzw. Sperrung des Zugangs durch
            Innamorato Solutions</b
          >
          <p>
            Innamorato Solutions kann seine Dienstleistungen betreffend Swiss Digital Shipping Solutions jederzeit ändern, aktualisieren, weiterentwickeln oder
            einstellen.
          </p>
          <p>
            Ebenso kann Innamorato Solutions aus technischen oder rechtlichen
            Gründen die Verfügbarkeit einschränken oder den Zugang gänzlich
            unterbinden.
          </p>
        </li>
        <li>
          <b>Beizug Dritter</b>
          <p>
            Der Kunde ist ausdrücklich damit einverstanden, dass Innamorato
            Solutions Dritte als Dienstleister beiziehen darf und dass dabei die
            Daten des Kunden, soweit erforderlich, an diese Dritte weitergegeben
            werden dürfen. Innamorato Solutions wendet bei diesem Beizug von
            Dritten die notwendige Sorgfalt in der Auswahl, Instruktion und
            Überwachung der Dritten an.
          </p>
        </li>
        <li>
          <b>Unabhängige Parteien</b>
          <p>
            Innamorato Solutions, Die Post CH AG, bexio AG und allfällige Dritte
            sind unabhängige Vertragsparteien. Keine Regelung dieser
            Vereinbarung beabsichtigt die Begründung eines arbeitsrechtlichen
            Verhältnisses, eines Agenturverhältnisses, eines Joint Ventures,
            einer gesellschaftsrechtlichen oder einer treuhänderischen
            Verbindung zwischen den Parteien.
          </p>
          <p>
            Weder einer der Parteien noch den Mitgliedern ihrer Organe, ihren
            Mitarbeitern und Agenten steht das Recht zu, im Namen oder auf
            Rechnung der anderen Partei zu handeln. Insbesondere steht keiner
            Partei oder einer dieser Personen das Recht zu, Verpflichtungen zu
            Lasten der anderen Partei einzugehen oder Versprechungen, Garantien
            und sonstige Erklärungen in deren Namen abzugeben.
          </p>
        </li>
        <li>
          <b>Salvatorische Klausel</b>
          <p>
            Sollte einer dieser Bestimmungen der AGB ungültig oder teilungültig
            sein, wird dadurch die Gültigkeit der anderen Bestimmungen nicht
            berührt.
          </p>
          <p>
            Innamorato Solutions und der Kunden verpflichten sich für diesen
            Fall, die unwirksame Bestimmung durch eine ihrem Sinn und Zweck
            weitmöglichst gleichwertige Bestimmung zu ersetzen
          </p>
        </li>
        <li>
          <b>Anwendbares Recht</b>
          <p>
            Die Rechtsbeziehung zwingen Innamorato Solutions und dem Kunden
            untersteht ausschliesslich dem materiellen schweizerischen Recht mit
            ausdrücklichem Ausschluss von Kollisionsrecht und Staatsverträgen.
          </p>
        </li>
        <li>
          <b>Gerichtsstand</b>
          <p>
            Ausschliesslicher Gerichtsstand für alle Verfahrensarten ist
            <b>Lenzburg</b>, vorbehältlich anderweitiger zwingender
            Bestimmungen.
          </p>
        </li>
      </ol>
      <p style="font-size: small">
        ©2023 Innamorato Solutions, alle Rechte vorbehalten
      </p>
    </div>
    <footer class="backgroundSPL2 pt-4">
      <b-container class="w-100 pt-2" fluid>
        <b-row>
          <b-col sm="4" class="pb-3">
            <b-img
              class="d-inline"
              style="max-width: 100px; margin-right: 5px"
              fluid
              src="./assets/black_icon_transparent_background.png"
            ></b-img>
            <span class="d-inline text-center align-middle h-100"
              >Innamorato Solutions</span
            >
          </b-col>
          <b-col sm="4" class="">
            <h6 class="text-muted">Unternehmen</h6>
            <ul class="list-unstyled">
              <li class="mb-2">
                <router-link class="text-muted" to="tos">AGBs</router-link>
              </li>
              <li class="mb-2">
                <router-link class="text-muted" to="imprint"
                  >Impressum</router-link
                >
              </li>
              <li class="mb-2">
                <a class="text-muted" href="mailto:contact@inn-solutions.ch"
                  >Kontakt</a
                >
              </li>
            </ul>
          </b-col>
          <b-col sm="4" class="">
            <h6 class="text-muted">Sitemap</h6>
            <ul class="list-unstyled mb-0">
              <li class="mb-2" style="cursor: pointer">
                <a class="text-muted" @click="scrollMeTo('vorteile', true)"
                  >Vorteile</a
                >
              </li>
              <li class="mb-2" style="cursor: pointer">
                <a class="text-muted" @click="scrollMeTo('gallerie', true)"
                  >Gallerie</a
                >
              </li>
              <li class="mb-2" style="cursor: pointer">
                <a class="text-muted" @click="scrollMeTo('preise', true)"
                  >Preise</a
                >
              </li>
              <li class="mb-2" style="cursor: pointer">
                <a class="text-muted" @click="scrollMeTo('integrationen', true)"
                  >Integrationen</a
                >
              </li>
            </ul>
          </b-col>
          <b-col sm="4" class=""> </b-col>
        </b-row>
        <!-- <b-row>
          <b-col cols="2" class="p-0"></b-col>
          <b-col cols="5">
            <h6 class="text-muted">Sitemap</h6>
            <ul class="list-unstyled mb-0">
              <li class="mb-2" style="cursor: pointer">
                <a class="text-muted" @click="scrollMeTo('vorteile', true)"
                  >Vorteile</a
                >
              </li>
              <li class="mb-2" style="cursor: pointer">
                <a class="text-muted" @click="scrollMeTo('gallerie', true)"
                  >Gallerie</a
                >
              </li>
              <li class="mb-2" style="cursor: pointer">
                <a class="text-muted" @click="scrollMeTo('preise', true)"
                  >Preise</a
                >
              </li>
              <li class="mb-2" style="cursor: pointer">
                <a class="text-muted" @click="scrollMeTo('integrationen', true)"
                  >Integrationen</a
                >
              </li>
            </ul></b-col
          >
          <b-col cols="5" class="p-0">
            <h6 class="text-muted">Unternehmen</h6>
            <ul class="list-unstyled">
              <li class="mb-2">
                <a class="text-muted" href="mailto:contact@inn-solutions.ch"
                  >Kontakt</a
                >
              </li>
              <li class="mb-2">
                <p href="#" class="text-muted">
                  Innamorato Solutions <br />Lenzhardweg 5 <br />5702 Niederlenz
                </p>
              </li>
            </ul></b-col
          >
        </b-row> -->
      </b-container>
      <!-- Copyrights -->
      <div class="py-4" style="background-color: #e9ece6">
        <div class="container text-center">
          <p class="mb-0">© 2023 Innamorato Solutions All rights reserved.</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "tos-SPL",
  components: {},
  data() {
    return {
      mobileMenu: false,
      expanded: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  mounted() {},
  methods: {
    goHome() {
      this.$router.push({ path: "/" });
    },
    handleResize() {
      this.mobileMenu = window.innerWidth > 1200 ? false : true;
    },
    scrollMeTo(refName, closeMobileNav = false) {
      if (closeMobileNav) {
        setTimeout(() => {
          this.expanded = false;
        }, 850);
      }
      var element = this.$refs[refName];
      var top = element.offsetTop - 100;
      window.scrollTo(0, top);
    },
  },
};
</script>

<style>
.price {
  font-size: x-large;
}
.priceExplanation {
  font-size: 0.875rem;
  color: rgba(123, 141, 148, 1);
}
.month {
  color: rgba(123, 141, 148, 1);
}
.modal-fullscreen .modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  position: fixed;
  z-index: 100000;
  background-color: none;
}

.modalBody #modalImg___BV_modal_content_ .modal-content {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.brandSPL {
  font-weight: bold;
  font-size: larger;
  color: black !important;
}
.navbarSPL2 {
  width: 100%;
  padding-top: 0.75rem !important;
  padding-bottom: 0.05rem !important;
  background-color: #e9ece6;
}
.btnSPL {
  background-color: #c0dfd9 !important;
  color: black !important;
  border: 0px !important;
  font-weight: bold !important;
  max-height: 10vw;
}
.btnSPL_2 {
  background-color: #c0dfd9 !important;
  color: black !important;
  border: 0px !important;
  font-weight: bold !important;
  max-height: 10vw;
}
.btnSPL2_2 {
  background-color: #c0dfd9 !important;
  color: black !important;
  border: 0px !important;
  font-weight: bold !important;
}
.toggleSPL {
  background-color: #e9ece6 !important;
  color: black !important;
  border: 0px !important;
  font-weight: bold !important;
}
.toggleSPL2 {
  background-color: #c0dfd9 !important;
  color: black !important;
  border: 0px !important;
  font-weight: bold !important;
}
.backgroundSPL2 {
  background-color: #e9ece6 !important;
}

footer {
  background-color: #e9ece6 !important;
}
.prev,
.next {
  cursor: pointer;
  top: 40%;
  width: auto;
  padding: 16px;
  color: #e9ece6;
  font-weight: bold;
  font-size: 18px;
  transition: 0.7s ease;
  border-radius: 0 4px 4px 0;
  text-decoration: none;
  user-select: none;
}

.next {
  right: 0;
}

.prev {
  left: 0;
}
</style>