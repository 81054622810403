<template>
  <div>
    <b-navbar toggleable class="navbarSPL2" :sticky="true">
      <b-navbar-nav class="w-100">
        <b-container class="bv-example-row" fluid>
          <b-row>
            <b-col cols="10" sm="10" md="10" lg="10" xl="5">
              <b-nav-text
                v-if="!mobileMenu"
                class="brandSPL"
                @click="goHome()"
                style="cursor: pointer"
              >
                <b-img
                  class="d-inline"
                  style="max-width: 70px; margin-right: 5px"
                  fluid
                  src="./assets/Logo_filled.svg"
                ></b-img>
                Swiss Digital Shipping Solutions
              </b-nav-text>
              <b-nav-text
                v-else
                class="brandSPL"
                @click="goHome()"
                style="cursor: pointer"
              >
                <b-img
                  class="d-inline"
                  style="max-width: 70px; margin-right: 5px"
                  fluid
                  src="./assets/Logo_filled.svg"
                ></b-img
                >SDSS
              </b-nav-text>
            </b-col>
            <b-col v-if="!mobileMenu" cols="0" lg="5">
              <b-nav style="padding-top: 20px">
                <b-nav-item
                  style="color: black; display: none"
                  :active="true"
                  @click="scrollMeTo('vorteile')"
                  >Vorteile</b-nav-item
                >
                <b-nav-item
                  style="color: black; display: none"
                  :active="true"
                  @click="scrollMeTo('gallerie')"
                  >Gallerie</b-nav-item
                >
                <b-nav-item
                  style="color: black; display: none"
                  :active="true"
                  @click="scrollMeTo('preise')"
                  >Preise</b-nav-item
                >
                <b-nav-item
                  style="color: black; display: none"
                  :active="true"
                  @click="scrollMeTo('integrationen')"
                  >Integrationen</b-nav-item
                >
              </b-nav>
            </b-col>
            <b-col v-if="!mobileMenu" cols="2" lg="2" style="padding-top: 15px">
              <b-button
                class="h-100 btnSPL_2"
                style="max-height: 50px; display: none"
                >Kostenlos testen</b-button
              >
            </b-col>
            <b-col
              style="padding-top: 15px"
              v-if="mobileMenu"
              class="d-flex justify-content-between"
              cols="2"
            >
              <b-button
                class="toggleSPL2"
                :active="true"
                v-b-modal.modalMobileMenu
                @click="expanded = !expanded"
                style="max-height: 45px; display: none"
              >
                <b-icon v-if="expanded" icon="x-lg" rotate="90"></b-icon>
                <b-icon v-else icon="list"></b-icon
              ></b-button>
            </b-col>
            <div
              v-show="expanded"
              style="box-shadow: rgba(0, 0, 0, 0.2) 0px 7px 8px 0px"
            >
              <b-container class="text-right mb-3">
                <br />
                <b-row class="w-100 pb-1" @click="scrollMeTo('vorteile', true)">
                  <b-col cols="12" class="p-0"
                    ><b-button class="w-100 btnSPL_2"
                      ><b-icon icon="box-seam"></b-icon>&emsp;Vorteile
                    </b-button></b-col
                  >
                </b-row>
                <b-row class="w-100 pb-1" @click="scrollMeTo('gallerie', true)">
                  <b-col cols="12" class="p-0"
                    ><b-button class="w-100 btnSPL_2"
                      ><b-icon icon="images"></b-icon>&emsp;Gallerie
                    </b-button></b-col
                  >
                </b-row>
                <b-row class="w-100 pb-1" @click="scrollMeTo('preise', true)">
                  <b-col cols="12" class="p-0"
                    ><b-button class="w-100 btnSPL_2"
                      ><b-icon icon="tag"></b-icon>&emsp;Preise
                    </b-button></b-col
                  >
                </b-row>
                <b-row
                  class="w-100 pb-1 mb-2"
                  @click="scrollMeTo('integrationen', true)"
                >
                  <b-col cols="12" class="p-0"
                    ><b-button class="w-100 btnSPL_2"
                      ><b-icon icon="code"></b-icon>&emsp;Integrationen
                    </b-button></b-col
                  >
                </b-row>
                <b-row class="w-100">
                  <div
                    class="w-100 mb-2"
                    style="background-color: #c0dfd9; height: 2px"
                  ></div>
                  <b-button class="h-100 btnSPL_2">Kostenlos testen </b-button>
                </b-row>
              </b-container>
            </div>
          </b-row>
        </b-container>
      </b-navbar-nav>
    </b-navbar>
    <div class="text-center w-100 h-100 pt-5">
      <h1 class="p-2">Impressum</h1>
      <span
        >Innamorato Solutions <br />Lenzhardweg 5 <br />5702 Niederlenz</span
      >
      <p>contact@inn-solutions.ch</p>
    </div>
    <footer class="backgroundSPL2 pt-4">
      <b-container class="w-100 pt-2" fluid>
        <b-row>
          <b-col sm="4" class="pb-3">
            <b-img
              class="d-inline"
              style="max-width: 100px; margin-right: 5px"
              fluid
              src="./assets/black_icon_transparent_background.png"
            ></b-img>
            <span class="d-inline text-center align-middle h-100"
              >Innamorato Solutions</span
            >
          </b-col>
          <b-col sm="4" class="">
            <h6 class="text-muted">Unternehmen</h6>
            <ul class="list-unstyled">
              <li class="mb-2">
                <router-link class="text-muted" to="tos">AGBs</router-link>
              </li>
              <li class="mb-2">
                <router-link class="text-muted" to="imprint"
                  >Impressum</router-link
                >
              </li>
              <li class="mb-2">
                <a class="text-muted" href="mailto:contact@inn-solutions.ch"
                  >Kontakt</a
                >
              </li>
            </ul>
          </b-col>
          <b-col sm="4" class="">
            <h6 class="text-muted">Sitemap</h6>
            <ul class="list-unstyled mb-0">
              <li class="mb-2" style="cursor: pointer">
                <a class="text-muted" @click="scrollMeTo('vorteile', true)"
                  >Vorteile</a
                >
              </li>
              <li class="mb-2" style="cursor: pointer">
                <a class="text-muted" @click="scrollMeTo('gallerie', true)"
                  >Gallerie</a
                >
              </li>
              <li class="mb-2" style="cursor: pointer">
                <a class="text-muted" @click="scrollMeTo('preise', true)"
                  >Preise</a
                >
              </li>
              <li class="mb-2" style="cursor: pointer">
                <a class="text-muted" @click="scrollMeTo('integrationen', true)"
                  >Integrationen</a
                >
              </li>
            </ul>
          </b-col>
          <b-col sm="4" class=""> </b-col>
        </b-row>
        <!-- <b-row>
          <b-col cols="2" class="p-0"></b-col>
          <b-col cols="5">
            <h6 class="text-muted">Sitemap</h6>
            <ul class="list-unstyled mb-0">
              <li class="mb-2" style="cursor: pointer">
                <a class="text-muted" @click="scrollMeTo('vorteile', true)"
                  >Vorteile</a
                >
              </li>
              <li class="mb-2" style="cursor: pointer">
                <a class="text-muted" @click="scrollMeTo('gallerie', true)"
                  >Gallerie</a
                >
              </li>
              <li class="mb-2" style="cursor: pointer">
                <a class="text-muted" @click="scrollMeTo('preise', true)"
                  >Preise</a
                >
              </li>
              <li class="mb-2" style="cursor: pointer">
                <a class="text-muted" @click="scrollMeTo('integrationen', true)"
                  >Integrationen</a
                >
              </li>
            </ul></b-col
          >
          <b-col cols="5" class="p-0">
            <h6 class="text-muted">Unternehmen</h6>
            <ul class="list-unstyled">
              <li class="mb-2">
                <a class="text-muted" href="mailto:contact@inn-solutions.ch"
                  >Kontakt</a
                >
              </li>
              <li class="mb-2">
                <p href="#" class="text-muted">
                  Innamorato Solutions <br />Lenzhardweg 5 <br />5702 Niederlenz
                </p>
              </li>
            </ul></b-col
          >
        </b-row> -->
      </b-container>
      <!-- Copyrights -->
      <div class="py-4" style="background-color: #e9ece6">
        <div class="container text-center">
          <p class="mb-0">© 2023 Innamorato Solutions All rights reserved.</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "tos-SPL",
  components: {},
  data() {
    return {
      mobileMenu: false,
      expanded: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  mounted() {},
  methods: {
    goHome() {
      this.$router.push({ path: "/" });
    },
    handleResize() {
      this.mobileMenu = window.innerWidth > 1200 ? false : true;
    },
    scrollMeTo(refName, closeMobileNav = false) {
      if (closeMobileNav) {
        setTimeout(() => {
          this.expanded = false;
        }, 850);
      }
      var element = this.$refs[refName];
      var top = element.offsetTop - 100;
      window.scrollTo(0, top);
    },
  },
};
</script>

<style>
.price {
  font-size: x-large;
}
.priceExplanation {
  font-size: 0.875rem;
  color: rgba(123, 141, 148, 1);
}
.month {
  color: rgba(123, 141, 148, 1);
}
.modal-fullscreen .modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  position: fixed;
  z-index: 100000;
  background-color: none;
}

.modalBody #modalImg___BV_modal_content_ .modal-content {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.brandSPL {
  font-weight: bold;
  font-size: larger;
  color: black !important;
}
.navbarSPL2 {
  width: 100%;
  padding-top: 0.75rem !important;
  padding-bottom: 0.05rem !important;
  background-color: #e9ece6;
}
.btnSPL {
  background-color: #c0dfd9 !important;
  color: black !important;
  border: 0px !important;
  font-weight: bold !important;
  max-height: 10vw;
}
.btnSPL_2 {
  background-color: #c0dfd9 !important;
  color: black !important;
  border: 0px !important;
  font-weight: bold !important;
  max-height: 10vw;
}
.btnSPL2_2 {
  background-color: #c0dfd9 !important;
  color: black !important;
  border: 0px !important;
  font-weight: bold !important;
}
.toggleSPL {
  background-color: #e9ece6 !important;
  color: black !important;
  border: 0px !important;
  font-weight: bold !important;
}
.toggleSPL2 {
  background-color: #c0dfd9 !important;
  color: black !important;
  border: 0px !important;
  font-weight: bold !important;
}
.backgroundSPL2 {
  background-color: #e9ece6 !important;
}

footer {
  background-color: #e9ece6 !important;
}
.prev,
.next {
  cursor: pointer;
  top: 40%;
  width: auto;
  padding: 16px;
  color: #545b5a;
  font-weight: bold;
  font-size: 18px;
  transition: 0.7s ease;
  border-radius: 0 4px 4px 0;
  text-decoration: none;
  user-select: none;
}

.next {
  right: 0;
}

.prev {
  left: 0;
}
</style>